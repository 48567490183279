import './messanger.css';
import Telegram from './img/icons8-телеграмма-app-48 (1).png';
import Avito from './img/avito-logo-01.png';
import Vk from './img/icons8-vk-48 (4).png'
const Messang =()=>{
    return (
        <div className="mesang">
            <a href=""><img src={Telegram} alt="" /></a>       
            <a href=""><img className='avito' src={Avito} alt="" /></a> 
            <a href="https://vk.com/feed" target='blank'><img src={Vk} alt="" /></a> 
        </div>
    )
}

export default Messang;