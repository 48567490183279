
import Product from "../Tematika/product";
import './productu.css'
import Marquee from "../strokaBeg/strokaBeg";

const Productu = () => {
    return(
        <div className="bloki" > 
          <Product/>
       

         
        </div>
    )
}

export default Productu; 