import Header from './component/header/header';
import TelegramForm from './component/forma/forma';
import Messang from './component/messanger/messanger';

import Productu from './component/img/productVse';
import './App.css';
import Video from './component/video/video';
import KamodVoprosov from './component/kamodVoprosov/kamod';
import KartaOtd from './component/kamodVoprosov/Снимок.JPG';
import Marquee from './component/strokaBeg/strokaBeg';
import ProductList from './component/tovar/tovar';
 import PhoneIcon from './component/ikonkaTelefona/telefon';
import App1 from './component/forma1/forma1';
function App() {
  const drawerData = [
    { title: 'Условия АКЦИИ', content: `${'При заказе свыше 1000 р  цена за кг становится 500 р (пример: при покупке кг пельменей и чебуреков всего 1000 р. На большой заказ нужен предзаказ) Действует до 31.12.2024'}` },
    { title: 'Доставка', content: <img className='karta' src={KartaOtd}/> , df:'Доставка осуществляется внутри красной выделенной линии,с 9:00 до 20:00. Доставка 100 рублей. При заказе выше 1000 рублей доставка бесплатная.' },
    { title: 'Процесс от заявки до получения', content: 'Вы оставляете заявку на доставку или самовывоз, с вами связываются в течении 15 минут и договариваемся о встрече, в назначенное время будет встреча.  При огромном заказе нужен предзаказ.' },
    { title: 'Состав', content: 'Фарш: (говядина - тазобедреннвя филейная часть(50%), свинина - яблочковая часть(50%), лук, черный перец. Тесто: мука, вода, яйцо, соль. Всегда все свежее.)' },

    { title: 'О нас', content: `${'Самозанятый'}` },
    { title: ' Хочу такой же сайт', content: `${' Точно такой же сайт будет стоить 5000 рублей. Можете написать в заказе, хочу  точно такой же сайт! Могу сделать под заказ другой сайт!'}` }
    
    
 
];
  return (
    <div className="app">
       <Video/>
       <Marquee name={`ТЕСТИРУЕМАЯ ВЕРСИЯ НА ДАННЫЙ МОМЕНТ УСЛУГА НЕДОСТУПНА!!!!!!!!!!!!!!! ОФОРМИ бесплатную доставку!!!  пельмени, чебуреки и фарш  приготовленные  руками,  из натурального мяса и вкуснейшего теста по действующей АКЦИИ  при заказе больше 1000 рублей цены становятся: 1 кг пельменей - 500  рублей, 1 кг чебуреков - 500  рублей, 1 кг фарша - 500  рублей, при заказе от 1000  рублей - действует  бесплатная доставка(есть ограничения)`}/>
        <Header/>
        
     
        <div className="poduct">
            <Productu/> 
            
        </div>
        
        <ProductList/>
      
        <p>Вопросы - ответы:</p>
        <KamodVoprosov drawers = {drawerData}/>
        <PhoneIcon/>
        <Marquee name='ТЕСТИРУЕМАЯ ВЕРСИЯ НА ДАННЫЙ МОМЕНТ УСЛУГА НЕДОСТУПНА!!!!!!!!!!!!!! АКЦИЯ 500 руб за кг при заказе от 1000 руб 1 кг  пельменей или чебуреков или фарша равен  500 руб + доставка становится бесплатная. Количество товара  ограничено!!!'/>
        {/* <TelegramForm/> */}
        <App1/>
        
        <Messang/>
       
    </div>
  );
}

export default App;
