// src/App.js
import React, { useState } from 'react';
import axios from 'axios';
import './forma1.css'; // Не забудьте создать файл со стилями

const products = [
    { id: 1, name: 'Пельмени', price: 650 },
    { id: 2, name: 'Чебуреки', price: 650 },
    { id: 3, name: 'Фарш', price: 650 },
];

const App1 = () => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [texteria, setTexteria] = useState('');
    const [cart, setCart] = useState({});
    const [isPolicyAccepted, setIsPolicyAccepted] = useState(false);

    const handleQuantityChange = (id, increment) => {
        setCart(prev => {
            const currentQuantity = prev[id] || 0;
            const newQuantity = increment ? currentQuantity + 1 : currentQuantity - 1;

            if (newQuantity > 10) {
                alert('Максимум 10 единиц на товар!');
                return prev;
            }
            return {
                ...prev,
                [id]: newQuantity > 0 ? newQuantity : 0,
            };
        });
    };

    const calculateTotal = () => {
        const totalItems = Object.values(cart).reduce((count, qty) => count + qty, 0);
        const totalPrice = totalItems > 0 ? (totalItems * (totalItems >= 2 ? 500 : products[0].price)) : 0;
        return totalPrice;
    };

    const handleSubmit = () => {
        if (!name || !phone || !isPolicyAccepted) {
            alert('Пожалуйста, заполните имя, телефон и примите политику конфиденциальности!');
            return;
        }

        const total = calculateTotal();
        const message = `Имя: ${name}\nТелефон: ${phone}\nТекстовое сообщение: ${texteria}\nКорзина:\n${Object.entries(cart).map(([id, qty]) => {
            const product = products.find(p => p.id === parseInt(id));
            const price = total > 1000 ? 500 : product.price; // Условие для изменения цены
            if (qty > 0) {
                return `${product.name} - ${qty} шт. - ${price * qty} руб.`; // Обновленная цена
            }
            return null;
        }).filter(item => item).join('\n')}\nИтого: ${total} руб.`;

        sendMessageToTelegram(message);
    };

    const sendMessageToTelegram = (message) => {
        const token = '6731657906:AAGCKDw_41ec9uYvZuIgDsnNVoU5KUsEWco';
        const chatId = '-1002123434386';
        const url = `https://api.telegram.org/bot${token}/sendMessage`;

        axios.post(url, {
            chat_id: chatId,
            text: message,
            parse_mode: 'HTML',
        }).then((response) => {
            console.log('Message sent:', response.data);
            alert('Информация успешно отправлена, с вами свяжутся в течение 15 минут!');
        }).catch((error) => {
            console.error('Error sending message:', error);
            alert('Ошибка при отправке информации.');
        });
    };

    const totalAmount = calculateTotal();

    return (
        <div className="container">
            <h1>Форма заполнения</h1>
            <div className="input-row">
                <input 
                    type="text" 
                    placeholder="Имя" 
                    value={name} 
                    onChange={(e) => setName(e.target.value)} 
                />
                <input 
                    type="text" 
                    placeholder="Телефон" 
                    value={phone} 
                    onChange={(e) => setPhone(e.target.value)} 
                />
            </div>
            <textarea 
                placeholder="Ваше сообщение " 
                value={texteria} 
                onChange={(e) => setTexteria(e.target.value)} 
            />
            <h2>ЗАКАЗ</h2>
            {products.map((product) => {
                const price = totalAmount > 1000 ? 500 : product.price;  
                return (
                    <div key={product.id} className="product-row">
                        <span className='span'>{product.name} - {price} руб.</span>
                        <div className="quantity-controls">
                            <button onClick={() => handleQuantityChange(product.id, false)}>-</button>
                            <span className='product1 '>{cart[product.id] || 0}</span>
                            <button onClick={() => handleQuantityChange(product.id, true)}>+    </button>
                        </div>
                    </div>
                );
            })}
            <h3>Итого: {totalAmount} руб.</h3>
            <div>
                <label>
                    <input 
                       
                        type="checkbox" 
                        checked={isPolicyAccepted} 
                        onChange={() => setIsPolicyAccepted(!isPolicyAccepted)} 
                    />
                    Я принимаю <a className='checkbox' href="https://yandex.ru/images/search?img_url=https%3A%2F%2Fworld-wells.ru%2Fwp-content%2Fuploads%2F1%2F2%2Fb%2F12b5a40b5813433dd51842923b241672.png&lr=50&pos=3&rpt=simage&source=serp&text=политика%20конфиденциальности%20для%20сайта%20пример" target="_blank">политику конфиденциальности</a>
                </label>
            </div>
            <button className='niznyyKnopka' onClick={handleSubmit}>Создать заказ</button>
        </div>
    );
};
export default App1;